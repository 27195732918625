import { createHashRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { ErrorBoundary } from 'src/components/ErrorBoundary';
import AppLayout from 'src/layout/AppLayout';
import { suspendPageLoad } from 'src/routes/suspendPageLoad';
import { detectFeatureToggles } from 'src/configuration/setup/featureToggles';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Intro = suspendPageLoad(() => import('../pages/Intro'));
const ConfLogicContainer = suspendPageLoad(() => import('src/pages/ConfLogicContainer'));
const TemplatesContainer = suspendPageLoad(() => import('src/pages/TemplatesContainer'));
// const UserSidebarLoader = suspendPageLoad(() => import('../features/users/userSidebar/UserSidebarLoader'));

export const DEFAULT_ROUTE = '/intro';
export const ROUTE_TEMPLATES = '/templates';
export const ROUTE_CONFLOGIC = '/conflogic';

export const routes = [DEFAULT_ROUTE, ROUTE_CONFLOGIC, ROUTE_TEMPLATES];
const { useConfLogic, useTemplates } = detectFeatureToggles();
const DefaultRoute = <Route path={DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<Intro />} />;
const ConfLogicRoute = (
    <Route path={ROUTE_CONFLOGIC} errorElement={<ErrorBoundary />} element={<ConfLogicContainer />} />
);
const TemplateRoute = (
    <Route path={ROUTE_TEMPLATES} errorElement={<ErrorBoundary />} element={<TemplatesContainer />} />
);
export const router = createHashRouter(
    createRoutesFromElements(
        <Route path='/' element={<AppLayout />}>
            {DefaultRoute}
            {useConfLogic && ConfLogicRoute}
            {useTemplates && TemplateRoute}
        </Route>
    )
);
