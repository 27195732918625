import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import configReducer from 'src/configuration/setup/configSlice';
import loginReducer from 'src/configuration/login/loginSlice';
import langReducer from 'src/configuration/lang/langSlice';
import tokenReducer from 'src/configuration/tokenHandling/tokenSlice';
import appReducer from 'src/data/appSlice';
import { conflogicApi } from 'src/services/conflogicApi';
import { templatesApi } from 'src/services/templatesApi';

export const setupStore = (preloadedState: any) => {
    return configureStore({
        reducer: {
            config: configReducer,
            lang: langReducer,
            app: appReducer,
            login: loginReducer,
            tokenHandling: tokenReducer,

            // Add the generated reducer as a specific top-level slice
            [conflogicApi.reducerPath]: conflogicApi.reducer,
            [templatesApi.reducerPath]: templatesApi.reducer,
        },
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(conflogicApi.middleware).concat(templatesApi.middleware),
    });
};

export const store = setupStore({});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
